import { Box, Card, CardActions, CardContent, CardMedia, List, Typography } from '@mui/material';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/services/redux/reducers';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import NewsCommentInput from 'src/modules/home/components/newsCommentInput';
import CommentsList from 'src/modules/home/components/commentsList';
import ParticipantsButton from 'src/modules/home/components/participantsButton';
import { useTranslation } from 'react-i18next';
import ActivityButtons from 'src/modules/home/components/activityButtons';
import ViewFile from 'src/modules/home/components/viewFile';
import { Colors } from 'src/shared/components/styles/colors';
import NewsActionLikeComment from 'src/modules/home/components/newsActionLikeComment';
import ReadConfirmButton from 'src/modules/home/components/readConfirmButton';
import { useFetchNewsById } from 'src/modules/home/hooks/queries/useFetchNewsById';
import { useMarkAsReadAndUnderstood } from 'src/modules/home/hooks/mutations/useMarkAsReadAndUnderstood';
import { NewsCategoryEnum } from 'src/shared/enums/newsCategory.enum';
import LockClockIcon from '@mui/icons-material/LockClock';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import activityGif from '../../../assets/images/calendar.gif';
import newsPaperGif from '../../../assets/images/newspaper.gif';
import { htmlParserOptions } from './newsListItem';

type NewsModalProps = {
  newsGUID: string;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
};

export const NewsModal = (props: NewsModalProps) => {
  const { modalOpen, setModalOpen, newsGUID } = props;
  const { t } = useTranslation(['news']);

  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { domain } = useSelector((state: RootStateType) => state.appState);

  // useQuery
  const newsItem = useFetchNewsById(newsGUID);

  // useMutation
  const markAsConfirmed = useMarkAsReadAndUnderstood();

  const markAsConfirmedHandler = async () => {
    markAsConfirmed.mutate(newsGUID);
  };

  return (
    <ModalTemplate
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      isLoading={newsItem.isLoading}
      title={`${newsItem?.data?.Heading}`}
      subtitle={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              color: Colors.GRAY_DARK,
              paddingRight: '5px',
              fontSize: '14px',
              textTransform: 'uppercase',
            }}
          >
            {dayjs(newsItem?.data?.PublishedDate).format('D MMM YYYY')}
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              display: 'flex',
              color: Colors.GRAY_DARK,
              alignItems: 'center',
            }}
          >
            <img width='14px' src={newsItem?.data?.Category === 'Aktiviteter' ? activityGif : newsPaperGif} alt='category' />
          </Typography>
          <Typography variant='subtitle1' sx={{ color: Colors.GRAY_DARK, fontSize: '14px', textTransform: 'uppercase' }}>
            {newsItem?.data?.Category}
          </Typography>
        </Box>
      }
      content={
        <>
          <Card
            sx={{
              maxWidth: '100%',
              boxShadow: 'none !important', // Overrida cards boxshadow
              display: 'flex',
              flexDirection: 'column',
              borderEndStartRadius: 0,
            }}
          >
            {newsItem?.data?.Image && (
              <CardMedia
                sx={{
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  maxHeight: 'auto',
                  maxWidth: '100%',
                  px: 1,
                  '&.MuiCardMedia-img': {
                    width: 'auto',
                    objectFit: 'contain',
                  },
                }}
                image={`${newsItem?.data?.Image}?access_token=${user?.access_token}`}
                component='img'
              />
            )}
            <CardContent>
              <Typography variant='h5' sx={{ fontSize: '20px' }}>
                {newsItem?.data?.Teaser}
              </Typography>
              <Typography variant='body2' component='div' sx={{ fontSize: '16px' }}>
                {parse(newsItem?.data?.BodyText ?? '', htmlParserOptions(user, domain))}
              </Typography>
              <List sx={{ display: 'flex', flexDirection: 'column', px: 0, mt: 2 }}>
                {newsItem?.data?.PDF && <ViewFile doc={newsItem?.data.PDF} />}
                {newsItem?.data?.PDFs && newsItem?.data.PDFs.map((doc) => <ViewFile key={doc} doc={doc} />)}
              </List>
            </CardContent>
            {newsItem?.data?.ShowConfirmAction && (
              <ReadConfirmButton readStatus={newsItem?.data.ConfirmedByMe} onClick={() => markAsConfirmedHandler()} />
            )}
            {newsItem?.data?.Category === NewsCategoryEnum.Activities && (
              <>
                {newsItem?.data?.ActivityDate && (
                  <Box display='flex' alignItems='center' flexDirection='row' px={1} mb={1}>
                    <CalendarMonthIcon />
                    <Typography variant='h6' pl={0.5}>
                      {t('news:activityDate')} {dayjs(newsItem?.data?.ActivityDate).format('dddd')}{' '}
                      {dayjs(newsItem?.data?.ActivityDate).format('DD MMM YYYY')} kl {dayjs(newsItem?.data?.ActivityDate).format('HH:mm')}
                    </Typography>
                  </Box>
                )}
                {newsItem?.data?.DeadlineDate && (
                  <Box display='flex' alignItems='center' flexDirection='row' px={1} mb={1}>
                    <LockClockIcon />
                    <Typography variant='h6' pl={0.5}>
                      {t('news:deadlineDate')} {dayjs(newsItem?.data?.DeadlineDate).format('YYYY-MM-DD')}
                    </Typography>
                  </Box>
                )}
              </>
            )}
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>{newsItem?.data?.Category === NewsCategoryEnum.Activities && <ActivityButtons newsItem={newsItem.data} />}</Box>
              <Box>{newsItem.data && newsItem.data.Participants.length > 0 && <ParticipantsButton items={newsItem.data.Participants} />}</Box>
            </CardActions>
          </Card>
          {newsItem.data && (
            <Box px={1} py={1}>
              <NewsActionLikeComment item={newsItem.data} />
            </Box>
          )}
          {newsItem?.data?.CanBeCommented && (
            <Box px={1}>
              <NewsCommentInput newsItem={newsItem.data} />
              <CommentsList items={newsItem.data} />
            </Box>
          )}
        </>
      }
    />
  );
};
