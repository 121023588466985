import { Avatar, Badge, Box } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAbsenceTypeID } from 'src/modules/calendar/components/helper';
import { AttendenceDTO } from 'src/services/api/models/attendanceDto';
import { RootStateType } from 'src/services/redux/reducers';
import { Colors } from 'src/shared/components/styles/colors';
import SantaHat from 'src/assets/images/santahat-02.png';

type AvatarWithBorderMeProps = {
  size: number;
  userToShow: AttendenceDTO;
  showThemeHat: boolean;
};

export const AvatarWithBorderMe = (props: AvatarWithBorderMeProps) => {
  const { size, userToShow, showThemeHat } = props;
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { t } = useTranslation();

  const today = new Date();
  const isDecember = today.getMonth() === 11;

  const getBorderColor = useCallback(() => {
    const result = {
      color: Colors.GREEN,
      text: t('news:checkedIn'),
    };
    switch (true) {
      case userToShow.onDuty:
        result.color = Colors.GREEN; // INCHECKAD TRUMFAR ÖVER ALLA ANDRA OAVSETT CURRENTABSENCE
        result.text = t('news:checkedIn');
        break;

      case !userToShow.onDuty && userToShow.currentAbsence === null:
        result.color = Colors.GRAY_BORDER; // UTCHECKAD UTAN FRÅNVAROTYP
        result.text = t('news:checkedOut');
        break;

      case !userToShow.onDuty && getAbsenceTypeID(userToShow.currentAbsence) === 1:
        result.color = Colors.RED; // LEDIGHET
        result.text = t('news:absence');
        break;

      case !userToShow.onDuty && getAbsenceTypeID(userToShow.currentAbsence) === 2:
        result.color = Colors.YELLOW; // TJÄNSTEÄRENDE
        result.text = t('news:businessTripAbsence');
        break;

      case !userToShow.onDuty && getAbsenceTypeID(userToShow.currentAbsence) === 3:
        result.color = Colors.GREEN; // DISTANS MEN INTE CHECKAT IN
        result.text = t('news:remoteAbsence');
        break;

      default:
        result.color = Colors.GRAY_BORDER;
        result.text = t('news:checkedOut');
        break;
    }

    return result;
  }, [userToShow.currentAbsence, userToShow.onDuty, t]);

  const { color, text } = getBorderColor();

  return (
    <Box>
      {showThemeHat && isDecember && (
        <img
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: size - 10,
            zIndex: 1,
            transform: 'rotate(-23deg)',
            marginTop: 3,
            marginLeft: 3,
          }}
          src={SantaHat}
          alt='santahat'
        />
      )}
      <Badge
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: color,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            padding: '0 5px',
            borderRadius: '12px',
            marginRight: '35%',
          },
        }}
        overlap='circular'
        badgeContent={text}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar
          src={`${userToShow.userImage}?access_token=${user?.access_token}`}
          sx={{
            width: size,
            height: size,
            borderRadius: '50%',
            border: `${size < 21 ? '1px' : '3px'}`,
          }}
        />
      </Badge>
    </Box>
  );
};
