import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import { RootStateType } from 'src/services/redux/reducers';
import { useSelector } from 'react-redux';
import { useFetchNewsById } from '../hooks/queries/useFetchNewsById';

type NewsLikedByModalProps = {
  newsGUID: string;
};

const NewsLikedByModal = (props: NewsLikedByModalProps) => {
  const { newsGUID } = props;
  const news = useFetchNewsById(newsGUID);
  const { user } = useSelector((state: RootStateType) => state.oidc);

  return (
    <Box m={2}>
      {news.isLoading ? (
        <>
          <Skeleton variant='rounded' width={150} height={36} sx={{ mb: 1.5 }} />
          <Skeleton variant='rounded' width={150} height={36} sx={{ mb: 1.5 }} />
        </>
      ) : (
        <>
          {news.data?.LikedBy &&
            news.data?.LikedBy.map((item) => (
              <Box key={item.ID} sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 1.5 }}>
                <Avatar
                  src={`${item.Image}?access_token=${user?.access_token}`}
                  // alt={item.Firstname.charAt(0)}
                  sx={{
                    width: 36,
                    height: 36,
                    borderRadius: '50%',
                    mr: 1.5,
                  }}
                />
                <Typography variant='body1'>
                  {item.Firstname} {item.Lastname}
                </Typography>
              </Box>
            ))}
        </>
      )}
    </Box>
  );
};

export default NewsLikedByModal;
