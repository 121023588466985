import { Box, Button, CardActions, CardContent, Divider, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { Colors } from 'src/shared/components/styles/colors';
import LikeLogo from 'src/shared/components/styles/likeLogo';
import NewsLikeButton from 'src/modules/home/components/newsLikeButton';
import { useState } from 'react';
import NewsLikedByModal from './newsLikedByModal';

type NewsActionLikeCommentProps = {
  item: NewsResponse;
  openComments?: () => void;
};

function NewsActionLikeComment(props: NewsActionLikeCommentProps) {
  const { item, openComments } = props;
  const { t } = useTranslation(['news']);
  const [likedByModalOpen, setLikedByModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onClickHandler = () => {
    if (openComments) openComments();
  };

  const openLikedBy = Boolean(anchorEl);
  const id = openLikedBy ? 'simple-popover' : undefined;

  const handleLikedByClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setLikedByModalOpen(!likedByModalOpen);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            px: 0,
          }}
        >
          <Typography variant='subtitle2' sx={{ display: 'flex', color: 'warning.main', minWidth: 0 }}>
            {item?.NumberOfLikes > 0 && (
              <>
                <Button aria-describedby={id} sx={{ color: Colors.ACCENT_DARK }} onClick={handleLikedByClick}>
                  <LikeLogo likes={item.NumberOfLikes} />
                </Button>
                <Popover
                  id={id}
                  open={likedByModalOpen}
                  onClose={() => setLikedByModalOpen(false)}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  {likedByModalOpen && <NewsLikedByModal newsGUID={item.GUID} />}
                </Popover>
              </>
            )}
          </Typography>
          {item.CanBeCommented && item.Comments.length > 0 && (
            // Kan bli kommenterad och har kommentarer
            <Button
              sx={{
                color: Colors.GRAY_DARK,
                minWidth: 0,
                textTransform: 'none',
                cursor: 'pointer',
                justifySelf: 'flex-end',
              }}
              onClick={onClickHandler}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: Colors.GRAY_DARK,
                }}
              >
                {item.Comments.length} {item.Comments.length === 1 ? t('comment') : t('comments')}
              </Typography>
            </Button>
          )}
          {item.CanBeCommented && item.Comments.length === 0 && (
            // Kan bli kommenterad men har inga kommentarer
            <Button
              sx={{
                color: Colors.GRAY_DARK,
                minWidth: 0,
                textTransform: 'none',
                cursor: 'pointer',
              }}
              onClick={onClickHandler}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: Colors.GRAY_DARK,
                }}
              >
                {t('Comment')}
              </Typography>
            </Button>
          )}
        </CardContent>
      }
      <Divider />
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <Box sx={{ alignItems: 'center', px: 0 }}>
          <NewsLikeButton item={item} />
        </Box>
      </CardActions>
    </Box>
  );
}

NewsActionLikeComment.defaultProps = {
  openComments: undefined,
};

export default NewsActionLikeComment;
