import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { employeeKeys } from 'src/modules/employee/hooks/employeeKeys';
import { EmployeeAPI } from 'src/services/api/employeeApi';
import { InfinityList } from 'src/services/api/helpers/infinityList';
import { PagedResult } from 'src/services/api/helpers/pagedResult';
import { EmployeeOptions } from 'src/services/api/options/employeeOptions';
import { EmployeeResponse } from 'src/services/api/response/employeeResponse';

const PAGE_SIZE = 10;

const employeeApi = new EmployeeAPI();
const getEmployees = async (options: EmployeeOptions) => {
  const res: PagedResult<EmployeeResponse> = await employeeApi.GetAll(options);
  return res.Results;
};

export const getInfinityEmployees = async (options: EmployeeOptions): Promise<InfinityList<EmployeeResponse>> => {
  const res: PagedResult<EmployeeResponse> = await employeeApi.GetAll(options);
  return {
    result: res.Results,
    nextPage: res.CurrentPage + 1,
    totalPages: res.TotalPages,
  };
};

export const getEmployeeById = async (employeeGuid: string) => {
  const res: EmployeeResponse = await employeeApi.Get(employeeGuid);
  return res;
};

export const useFetchInfinityEmployees = (options: EmployeeOptions) => {
  return useInfiniteQuery({
    queryKey: employeeKeys.infinityList(options),
    queryFn: ({ pageParam = 0 }) =>
      getInfinityEmployees({
        ...options,
        PageIndex: pageParam,
        PageSize: PAGE_SIZE,
      }),
    getNextPageParam: (lastPage: InfinityList<EmployeeResponse>) => {
      if (lastPage.result.length === PAGE_SIZE && lastPage.nextPage < lastPage.totalPages) return lastPage.nextPage;
      return undefined;
    },
    keepPreviousData: true,
    retry: 1,
    refetchOnMount: 'always',
  });
};

export const useFetchEmployees = (options: EmployeeOptions) => {
  return useQuery({
    queryKey: employeeKeys.list(options),
    queryFn: () => getEmployees(options),
    staleTime: 60 * 4 * 1000,
  });
};

export const useFetchEmployeeById = (employeeGuid: string) => {
  return useQuery({
    queryKey: employeeKeys.byId(employeeGuid),
    queryFn: () => getEmployeeById(employeeGuid),
  });
};
